import { useState, useEffect } from 'react';
import { buildPromoRO, formatSCMPrefs } from 'wsm-specials-display';
import { useRequestData, usePrefs } from 'wsm-common-data';
import { hysteriFetch, wrappers } from 'hysterics';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { setScmPromos } from '../features/contentCards';

const NR_PREFIX = 'PROMOS';
const ENDPOINT_FETCH_ERROR = `${NR_PREFIX}/CLIENT/PROMOS ENDPOINT FETCH ERROR`;

const promoFetcher = hysteriFetch('specialsData');

export const useSCMPromos = (flags) => {
	const [promosResponse, setPromosResponse] = useState({
		promos: [],
		personalized: false
	});
	const dispatch = useDispatch();

	let listingConfig;
	const prefs = usePrefs();
	const { locale, deviceType } = useRequestData();
	const scmPrefs = formatSCMPrefs(prefs, deviceType);
	const { scmEnabled, vehicleCardLayout } = prefs;
	const { accountId } = useSelector((state) => state.widgetInfo);

	const isWindowPresent =
		typeof window !== 'undefined' && Boolean(window.Cookies);
	let pixallId = null;
	if (isWindowPresent) {
		pixallId =
			window.Cookies.get('abc') ?? window.Cookies.get('pxa_id') ?? null;
	}

	if (isBrowser && window.DDC?.InvData?.prefs) {
		listingConfig = window.DDC?.InvData?.prefs['listing.config.id'];
	}

	const completePrefs = Object.assign(scmPrefs, {
		'listing.config.id': listingConfig
	});

	const parameters = buildPromoRO(
		completePrefs,
		accountId,
		locale,
		pixallId,
		deviceType
	);

	const requestUrl = '/api/widget/ws-specials/promos';

	useEffect(() => {
		const showPromosOnlyOnGrid =
			flags['wsm-specials-display-vertical-promos-only'];
		const shouldFetchPromos = showPromosOnlyOnGrid
			? vehicleCardLayout === 'grid'
			: true;

		if (scmEnabled === 'true' && shouldFetchPromos) {
			promoFetcher(
				requestUrl,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json;charset=UTF-8'
					},
					body: JSON.stringify({
						parameters
					})
				},
				{
					[wrappers.timeout.contextKey]: {
						timeout: flags['ws-listing-promo-client-timeout']
					},
					[wrappers.retry.contextKey]: {
						retries: flags['ws-listing-promo-client-retries']
					}
				}
			)
				.then((response) => {
					if (!response) {
						setPromosResponse({
							promos: [],
							personalized: false
						});
						dispatch(
							setScmPromos({
								promosList: [],
								personalized: false
							})
						);
						return;
					}
					const { promos, personalized } = response;

					setPromosResponse({
						promos,
						personalized
					});
					dispatch(
						setScmPromos({ promosList: promos, personalized })
					);
				})
				.catch((err) => {
					setPromosResponse({
						promos: [],
						personalized: false
					});
					dispatch(
						setScmPromos({ promosList: [], personalized: false })
					);
					throw new Error(`${ENDPOINT_FETCH_ERROR}: ${err.message}`);
				});
			/* eslint-disable react-hooks/exhaustive-deps */
		}
	}, []);

	return promosResponse;
};
