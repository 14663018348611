import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRequestData } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { selectIsTabbedLayout } from '../../../../features/layoutSlice';
import { publishVehicleData } from '../../../../utilities/vehicleTracking';
import MyCarsDetails from './MyCarsDetails';
import { useDeviceType } from '../../../../hooks';

const DetailsButton = ({ uuid, link, title }) => {
	const isDesktop = useDeviceType();
	const { windowId, widgetName } = useRequestData();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isInline = !isTabbedLayout;

	const handleClick = () => {
		trackEvent(widgetName, windowId, {
			element: 'View details link',
			result: 'Navigated to VDP'
		});
		publishVehicleData(uuid);
	};

	return (
		<MyCarsDetails
			title={title}
			link={link}
			onClick={handleClick}
			isInline={isInline}
			isDesktop={isDesktop}
		/>
	);
};

DetailsButton.propTypes = {
	uuid: PropTypes.string,
	link: PropTypes.string,
	title: PropTypes.arrayOf(PropTypes.string)
};

export default DetailsButton;
