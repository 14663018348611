import React, { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { trackEvent } from 'ddc-track-event';
import PropTypes from 'prop-types';
import { useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import GeoRange from './GeoRange';
import LocationInput from './LocationInput';

const FacetGeoLocation = ({ facetTitle, geoZip }) => {
	const { widgetName, windowId, deviceType } = useRequestData();
	const geoRadiusFilter =
		isBrowser &&
		window?.DDC?.InvData?.getAppliedFilters()?.find(
			(filter) => filter.id === 'geoRadius'
		);
	const geoRadiusValue = geoRadiusFilter
		? geoRadiusFilter.values[0].value
		: '0';
	const [range, setRange] = useState(geoRadiusValue);

	useEffect(() => {
		if (range !== geoRadiusValue) {
			setRange(geoRadiusValue);
		}
	}, [range, geoRadiusValue]);

	const updateRange = (e) => {
		const newRange = e.target.value;
		if (newRange !== range && isBrowser) {
			setRange(newRange);
			window.DDC.InvData.setFilterSelections({
				filterName: 'geoRadius',
				filterValues: [newRange],
				filterValue: [newRange],
				filterTitleText: facetTitle,
				element: e.target,
				isSelected: true,
				isSlider: false,
				isChip: true
			});
		}
		trackEvent(widgetName, windowId, {
			action: 'applied',
			element: 'GeoRadius selection',
			elementCTA: 'GeoRadius',
			fieldValue: newRange,
			result: 'Results updated with facet added'
		});
	};

	return (
		<div
			className={setClassNames([
				'geo-location-wrapper',
				'd-flex',
				'flex-wrap',
				'justify-content-center',
				'border-top border-bottom',
				'py-6 mb-6',
				deviceType === 'DESKTOP' ? '' : 'flex-column'
			])}
		>
			<GeoRange range={range} updateRange={updateRange} />
			<LocationInput geoZip={geoZip} />
		</div>
	);
};

FacetGeoLocation.propTypes = {
	facetTitle: PropTypes.string,
	geoZip: PropTypes.arrayOf('')
};

export default FacetGeoLocation;
