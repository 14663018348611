import { SET_LAYOUT } from '../actions/types';

export default function setLayout(state = {}, action) {
	if (!action.error && action.payload) {
		if (action.type === SET_LAYOUT) {
			const { payload } = action;
			return {
				...state,
				...payload
			};
		}
	}

	return state;
}
