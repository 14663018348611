import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DetailsSkeleton, {
	DetailsSkeletonContainerClasses
} from '../skeleton/DetailsSkeleton';
import TitleSkeleton from '../skeleton/TitleSkeleton';
import VehicleTitle from './VehicleTitle';
import Details from './Details';

const DetailsContainer = ({ shouldFullyRenderCard, ...props }) => {
	const [isDisplayingEPrice, setIsDisplayingEPrice] = useState(false);
	const [showExpandedPricing, setShowExpandedPricing] = useState(false); // todo pref sets initial state to show all pricing when configured
	return (
		<div
			className={`vehicle-card-details-container${
				!shouldFullyRenderCard
					? ` ${DetailsSkeletonContainerClasses.join(' ')}`
					: ''
			}`}
		>
			{props.vehicle && !props.vehicle.isPlaceholder ? (
				<VehicleTitle
					title={props.vehicle.title}
					link={props.vehicle.link}
					vehicleId={props.vehicle.uuid}
					condition={props.vehicle.condition}
					pricing={props.vehicle.pricing || {}}
					isDisplayingEPrice={isDisplayingEPrice}
					accountInfo={{ ...props.accounts[props.vehicle.accountId] }}
					showExpandedPricing={showExpandedPricing}
					setShowExpandedPricing={setShowExpandedPricing}
				/>
			) : (
				<TitleSkeleton />
			)}

			{shouldFullyRenderCard ? (
				<Details
					{...props}
					isDisplayingEPrice={isDisplayingEPrice}
					setIsDisplayingEPrice={setIsDisplayingEPrice}
					showExpandedPricing={showExpandedPricing}
					setShowExpandedPricing={setShowExpandedPricing}
				/>
			) : (
				<DetailsSkeleton />
			)}
		</div>
	);
};

DetailsContainer.propTypes = {
	vehicle: PropTypes.shape({
		title: PropTypes.arrayOf(PropTypes.string),
		uuid: PropTypes.string,
		link: PropTypes.string,
		condition: PropTypes.string.isRequired,
		pricing: PropTypes.shape({}),
		accountId: PropTypes.string,
		isPlaceholder: PropTypes.bool
	}),
	accounts: PropTypes.shape({}),
	isTabbedLayout: PropTypes.bool.isRequired,
	isGridLayout: PropTypes.bool,
	shouldFullyRenderCard: PropTypes.bool.isRequired,
	accountId: PropTypes.string.isRequired
};

export default DetailsContainer;
