import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { ErrorBoundary } from 'wsm-error-boundary';
import { selectIsGridLayout } from '../features/layoutSlice';

const GenericAdCard = ({ position, forwardedRef, dataLocation, type }) => {
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));

	return (
		<ErrorBoundary newRelicPageAction="ws-inv-listing error boundary: Hyundai special placeholder location error">
			<li
				data-location={dataLocation || 'listings-ad-1'}
				id={`${type}-card-${position}`}
				ref={forwardedRef}
				key={`${type}-card-${position}`}
				className={setClassNames([
					'box border-0',
					'vehicle-card',
					'd-flex flex-wrap justify-content-center flex-child-full-width',
					'vehicle-card-detailed',
					isGridLayout ? null : 'vehicle-card-horizontal'
				])}
			/>
		</ErrorBoundary>
	);
};

const ReferencedGenericAdCard = React.forwardRef((props, ref) => {
	return <GenericAdCard {...props} forwardedRef={ref} />;
});

GenericAdCard.propTypes = {
	position: PropTypes.string.isRequired,
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	dataLocation: PropTypes.string,
	type: PropTypes.string.isRequired
};

export default ReferencedGenericAdCard;
