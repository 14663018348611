import { SET_USER_PROFILE } from '../actions/types';

export default function setUserProfile(state = {}, action) {
	if (!action.error && action.payload) {
		if (action.type === SET_USER_PROFILE) {
			const {
				payload: { userProfile }
			} = action;
			return {
				...state,
				...userProfile
			};
		}
	}
	return state;
}
