import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import CompareContent from './CompareContent';
import { selectIsTabbedLayout } from '../../../../features/layoutSlice';
import { useMyCarsSiteProperties, useDeviceType } from '../../../../hooks';

const MyCarsLabel = ({ selected, handleChange, uuid }) => {
	const labels = useLabels();
	const isDesktop = useDeviceType();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const { enableMyCars } = useMyCarsSiteProperties();
	const isInline = !isTabbedLayout;

	const modernMyCarsIcon = selected
		? 'ddc-icon-checkmark2'
		: 'ddc-icon-checkmark2-outline';

	const modernMyCarsIconClasses = [
		'ddc-icon',
		modernMyCarsIcon,
		isDesktop ? 'ddc-icon-size-small' : ''
	];

	const modernMyCarsCtasBtnClasses = [
		'd-flex ',
		isInline || !enableMyCars ? 'flex-row' : 'flex-column',
		'align-items-center',
		'py-1',
		'px-1'
	];

	const modernMyCarsCtasBtnLabelClasses = [
		isInline || !enableMyCars ? 'ml-3' : '',
		'label-text',
		'font-weight-normal'
	];

	return (
		<>
			<div className="d-flex align-items-center justify-content-center">
				<button
					type="button"
					className={setClassNames([
						'modern-my-cars-compare-label',
						'border-0',
						'bg-none',
						'p-0',
						selected ? 'text-primary-700 text-decoration-none' : ''
					])}
					onClick={(e) => handleChange(e)}
					id={`${uuid}-compare`}
				>
					<span className={setClassNames(modernMyCarsCtasBtnClasses)}>
						<i
							data-testid="modern-my-cars-compare-label-icon"
							className={setClassNames(modernMyCarsIconClasses)}
							aria-hidden="true"
						/>
						<span
							className={setClassNames(
								modernMyCarsCtasBtnLabelClasses
							)}
						>
							{labels.get('COMPARE')}
						</span>
					</span>
				</button>
				{isInline ? (
					<CompareContent selected={selected} isInline={isInline} />
				) : null}
			</div>
		</>
	);
};

MyCarsLabel.propTypes = {
	uuid: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default MyCarsLabel;
