import { SET_MODAL_SETTINGS } from '../actions/types';

export default function modalSettings(
	state = {
		isModalOpen: false,
		videos: [],
		uuid: null,
		title: '',
		link: '',
		phone: ''
	},
	action
) {
	if (!action.error && action.payload) {
		const newProps = action.payload;
		switch (action.type) {
			case SET_MODAL_SETTINGS: {
				return {
					...state,
					...newProps
				};
			}
			default:
				return state;
		}
	}
	return state;
}
