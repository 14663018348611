import { useMyCarsSiteProperties } from './useMyCarsSiteProperties';

export const useFooterLayoutClasses = (
	isDesktop,
	isTabbedLayout,
	shouldRenderFullCard,
	isGridLayout,
	shouldShowCompare
) => {
	const { enableMyCars } = useMyCarsSiteProperties();
	const isInlineLayout = !isTabbedLayout;

	const baseClasses = [
		'vehicle-card-footer',
		'text-muted',
		'd-flex',
		'align-items-center',
		'overflow-hidden',
		'border-0'
	];

	const fontSizeClass = isInlineLayout
		? 'ddc-font-size-small'
		: 'ddc-font-size-xsmall';
	baseClasses.push(fontSizeClass);

	if (isInlineLayout && isDesktop) {
		baseClasses.push(
			'justify-content-around',
			shouldShowCompare ? 'gap-2-rem' : 'gap-4-rem'
		);
	} else if (enableMyCars) {
		baseClasses.push(
			'footer-grid-layout',
			shouldShowCompare ? 'columns-4' : 'columns-3'
		);
	} else {
		baseClasses.push('justify-content-between', 'gap-2-rem');
	}

	if (isDesktop && !isTabbedLayout && !shouldRenderFullCard) {
		baseClasses.push('mx-4');
	}
	if (isGridLayout || !isDesktop) {
		baseClasses.push('mt-2', 'mb-2', 'pt-3', 'pb-3');
	}

	return baseClasses;
};
