import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'wsm-skeleton';
import { selectIsTabbedLayout } from '../../../features/layoutSlice';

const TitleSkeleton = () => {
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	return (
		<Skeleton
			skeletonClasses="mb-6"
			height="2rem"
			width={isTabbedLayout ? '100%' : '75%'}
			dataTestId="title-skeleton"
		/>
	);
};

export default TitleSkeleton;
