import { SET_CTA_CARD_ITEMLIST } from '../actions/types';

export default function setCtaCardItemlist(state = {}, action) {
	if (!action.error && action.payload) {
		if (action.type === SET_CTA_CARD_ITEMLIST) {
			return action.payload;
		}
	}

	return state;
}
