import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLabels, useRequestData, useSitemap } from 'wsm-common-data';
import { useWindowWidth } from 'wsm-srp-utilities';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { getSpotIconSize } from '../utilities/layout';
import { selectIsGridLayout } from '../features/layoutSlice';
import SpotIcon from './SpotIcon';

const CtaCard = ({
	buttonLabel,
	buttonStyle,
	buttonSize,
	headingLabel,
	contentLabel,
	iconName,
	position,
	url,
	displayStyle,
	accessibleDescription,
	forwardedRef
}) => {
	const labels = useLabels();
	const sitemap = useSitemap();
	const { deviceType, windowId } = useRequestData();
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));

	const viewportWidth = useWindowWidth();
	const isLargeWindow = viewportWidth && viewportWidth > 1200;
	const resolvedHref = sitemap.get(url) || url;

	const topSpacing = ['mb-0', 'mt-5', isGridLayout ? 'mt-lg-6' : 'mt-sm-0'];
	const bottomSpacing = ['mb-0', 'mt-3'];

	const bodyText = contentLabel && (
		<p
			className={setClassNames(
				displayStyle === 'icon-content-heading-cta-centered'
					? topSpacing
					: bottomSpacing
			)}
		>
			{labels.getHTML(contentLabel)}
		</p>
	);

	const headingText = headingLabel && (
		<h3
			id={`ctaCard-${position}-heading`}
			className={setClassNames(
				displayStyle === 'icon-content-heading-cta-centered'
					? bottomSpacing
					: topSpacing
			)}
		>
			{labels.get(headingLabel)}
		</h3>
	);

	return (
		<li
			data-location={`cta-card-${position}`}
			ref={forwardedRef}
			key={position}
			className={setClassNames([
				'box',
				'box-border',
				'vehicle-card',
				'vehicle-card-detailed',
				isGridLayout ? null : 'vehicle-card-horizontal'
			])}
		>
			<div
				className={setClassNames([
					'cta-card-body',
					'ddc-font-size-small',
					'align-items-center',
					'd-md-flex',
					'text-center',
					'py-8',
					'px-5',
					'px-sm-10',
					isGridLayout ? 'd-flex flex-column py-sm-10' : 'py-sm-6'
				])}
			>
				<div
					className={setClassNames([
						'cta-card-media-wrapper',
						'px-0',
						isGridLayout ? 'col-sm-12' : 'col-sm-6 col-lg-3'
					])}
				>
					<div className="cta-card-media-icon line-height-reset">
						<SpotIcon
							spotIconName={iconName}
							spotIconSize={getSpotIconSize(deviceType)}
						/>
					</div>
				</div>
				<div
					className={setClassNames([
						'cta-card-content-wrapper',
						'align-items-center',
						'px-0',
						isGridLayout ? null : 'col-sm-6 col-lg-9 d-xl-flex'
					])}
				>
					<div
						className={setClassNames([
							'cta-card-content-container',
							'p-0',
							'text-center',
							'align-middle',
							'col-12',
							isGridLayout ? null : 'col-lg-8'
						])}
					>
						{displayStyle === 'icon-content-heading-cta-centered'
							? bodyText
							: headingText}
						{displayStyle === 'icon-content-heading-cta-centered'
							? headingText
							: bodyText}
					</div>
					<div
						className={setClassNames([
							'cta-card-btn-container',
							'pt-4',
							isLargeWindow ? 'pb-sm-4' : null,
							isGridLayout ? 'pt-lg-6' : 'col-lg-4'
						])}
					>
						{buttonLabel && (
							<a
								type="button"
								className={setClassNames([
									'btn',
									buttonSize === 'BLANK' ? '' : buttonSize,
									buttonStyle
								])}
								href={resolvedHref}
								title={labels.get(accessibleDescription)}
								onClick={() => {
									trackEvent(
										`cta-card-${position}`,
										windowId,
										{
											element: 'CTA Button/Link',
											elementCTA: labels.get(buttonLabel),
											result: `Navigated to ${url}`
										}
									);
								}}
							>
								{labels.get(buttonLabel)}
							</a>
						)}
					</div>
				</div>
			</div>
		</li>
	);
};

const ReferencedCtaCard = React.forwardRef((props, ref) => {
	return <CtaCard {...props} forwardedRef={ref} />;
});

CtaCard.propTypes = {
	buttonLabel: PropTypes.string.isRequired,
	buttonStyle: PropTypes.string.isRequired,
	buttonSize: PropTypes.string.isRequired,
	headingLabel: PropTypes.string.isRequired,
	contentLabel: PropTypes.string.isRequired,
	iconName: PropTypes.string.isRequired,
	position: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	displayStyle: PropTypes.string.isRequired,
	accessibleDescription: PropTypes.string.isRequired,
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

export default ReferencedCtaCard;
