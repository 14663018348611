import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'wsm-skeleton';
import { selectIsTabbedLayout } from '../../../features/layoutSlice';

export const DetailsSkeletonContainerClasses = ['p-0', 'mt-8'];

const DetailsSkeleton = () => {
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	return (
		<>
			<div className="row" data-testid="details-skeleton">
				<div className="col-xs-6">
					<div className="skeleton-listing" />
				</div>
				<div className="col-xs-6">
					<div className="skeleton-listing" />
				</div>
			</div>
			{!isTabbedLayout && (
				<Skeleton
					skeletonClasses="ml-auto mt-6"
					width="48%"
					height="3rem"
					dataTestId="price-skeleton"
				/>
			)}
		</>
	);
};

export default DetailsSkeleton;
