import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'wsm-error-boundary';
import WebIntegrationPlaceholder from './WebIntegrationPlaceholder';
import { selectIsGridLayout } from '../features/layoutSlice';

const WebIntegrationListingsPlaceholder = ({ location, forwardedRef }) => {
	const [prefixKey, setPrefixKey] = useState('server');
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));

	useEffect(() => {
		setPrefixKey('client');
	}, []);

	const bannerClasses = isGridLayout ? 'w-100 px-4 mb-5' : 'mb-5';
	const isPlaceholderFive = location === 'listings-placeholder-5';
	const classNames = [
		'vehicle-card',
		'placeholder-card',
		'd-flex flex-wrap justify-content-center flex-child-full-width',
		// 'vehicle-card-detailed', // WIAPI will add this if the card needs to be rendered
		isGridLayout ? null : 'vehicle-card-horizontal',
		bannerClasses,
		isPlaceholderFive ? 'flex-gap-1-rem' : null
	];
	return (
		<ErrorBoundary newRelicPageAction="ws-inv-listing error boundary: WIAPI Listings placeholder location error">
			<WebIntegrationPlaceholder
				location={location}
				classNames={classNames}
				tagName="li"
				key={`${prefixKey}-${location}`}
				forwardedRef={forwardedRef}
			/>
		</ErrorBoundary>
	);
};

const ReferencedWebIntegrationListingsPlaceholder = React.forwardRef(
	(props, ref) => {
		return (
			<WebIntegrationListingsPlaceholder {...props} forwardedRef={ref} />
		);
	}
);

WebIntegrationListingsPlaceholder.propTypes = {
	location: PropTypes.string.isRequired,
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

export default ReferencedWebIntegrationListingsPlaceholder;
