import {
	MIN_COLUMNS_FOR_POSITIONING,
	DEFAULT_MAX_COLUMNS_ON_SRP,
	inventoryPageSizes
} from '../global-constants';

// use 5 cards instead of 3, SCM is always priority
// if 18 or more vehicles show 5 cards
// 14 or more vehicles show 4 cards,
// 10 or more vehicles show 3 cards,
// 4 or more vehicles show 2 cards,
// else show 1 card
/**
 * A utility function which determines how many "non-vehicle" cards to show on the SRP based on inventory size
 * @param {number} invSize Size of the vehicle inventory
 * @returns {number} Number of non vehicle cards we can show on SRP. Falls back to value of MD_PAGE_SIZE
 */
export const cardsToShow = (invSize) => {
	const { SM_PAGE_SIZE, MD_PAGE_SIZE, LG_PAGE_SIZE, XL_PAGE_SIZE } =
		inventoryPageSizes;
	let numCards = 1;
	if (invSize >= XL_PAGE_SIZE) numCards = 5;
	else if (invSize >= LG_PAGE_SIZE) numCards = 4;
	else if (invSize >= MD_PAGE_SIZE) numCards = 3;
	else if (invSize >= SM_PAGE_SIZE) numCards = 2;
	return numCards;
};

/**
 * Method to calculate positions for cta or adcards based on num columns, size of inventory, and number of special cards allowed.
 * - First card will always be the last of the first row
 * - Middle card is calculated as halfway between the list of all cards
 * - Last card will always be first in the last row
 * @param {number} totalCards Total of vehicle inventory and number of special cards to show
 * @param {number} columns Number of columns per row, also is the num VehicleCards per row
 * @returns {number[]} Array of positions that cards should be placed in with even distribution.
 */
export const getPrimaryNonVehicleCardPositions = (totalCards, columns) => {
	const positions = [];
	// we use newColumns here because if there are less than 2 columns (MIN_COLUMNS_FOR_POSITIONING) on the page we still want
	// to use 2 in our calculations for spacing purposes.
	const newColumns =
		columns < MIN_COLUMNS_FOR_POSITIONING
			? MIN_COLUMNS_FOR_POSITIONING
			: columns;

	const lastRowNumCardsNotComplete = totalCards % newColumns;

	const first = newColumns - 1;
	positions.push(first);

	const second = Math.round(totalCards / 2);
	positions.push(second);

	const last =
		lastRowNumCardsNotComplete > 0
			? totalCards - lastRowNumCardsNotComplete
			: totalCards - newColumns;
	positions.push(last);

	return positions;
};

/**
 * Utility function to generate insertion positions for SCM Promos into the vehicle card list for the SRP.
 * @param {number} numTotalCards
 * @param {number} columns
 * @param {number} numNonVehicleCards
 * @returns List of positions to insert SCM Promo cards.
 */
export const getSCMPromoPositions = (
	numTotalCards,
	columns,
	numNonVehicleCards
) => {
	const positions = [];

	const newColumns =
		columns < MIN_COLUMNS_FOR_POSITIONING
			? MIN_COLUMNS_FOR_POSITIONING
			: columns;

	for (let i = 0, j = 1; i < numNonVehicleCards; i++, j++) {
		// on XL sized pages we space out promos appearance to be further down on SRP
		const xlPagesAccomodation =
			numTotalCards - numNonVehicleCards >=
			inventoryPageSizes.XL_PAGE_SIZE
				? j + 1
				: j;
		const pos =
			i <= 1
				? newColumns * j + i - 1
				: newColumns * xlPagesAccomodation + i;
		positions.push(pos);
	}

	return positions;
};

/**
 * Checks the parent container class (set via the containerMaxWidth group preference)
 * to determine the max number of columns of vehicle cards.
 * If you update the number of columns per container size, you must also update in cms-web
 * https://ghe.coxautoinc.com/DDC-WebPlatform/cms-web/blob/master/htdocs/v9/media/sass/ddc-design-system/components/_vehicle-card.scss
 * @param {boolean} isBrowser Boolean that identifies whether the code is running in the browser
 * @param {string} windowId The windowId from request data
 * @returns {number} Number of allowed columns based on parent containers of SRP
 */
export const getMaxColumnsByContainer = (isBrowser = false, windowId = '') => {
	let maxColumnsInContainer = DEFAULT_MAX_COLUMNS_ON_SRP;

	if (isBrowser) {
		// smallerContainerClasses includes all except container-max-xl (the largest) and container-max-sm (not supported by the styles)
		const smallerContainerClasses = [
			'.container-max-md',
			'.container-max-lg'
		];
		let smallerContainerSelector = smallerContainerClasses
			.map(
				(container) =>
					`${container}:has([data-widget-id="${windowId}"])`
			)
			.join(', ');
		let smallerContainers;

		try {
			smallerContainers = document.querySelectorAll(
				smallerContainerSelector
			);
		} catch (_e) {
			// If we're here, then the browser does not support the :has() pseudo-class,
			// so we query for the most common listings wrapper section names
			const listingsWrappers = [
				'[data-name="srp-wrapper-combined-inner"]',
				'[data-name="srp-wrapper-combined"]',
				'[data-name="inventory-search-results-combined-1"]',
				'[data-name="inventory-search-results-combined-inner-1"]'
			];
			smallerContainerSelector = smallerContainerClasses
				.map((container) =>
					listingsWrappers
						.map((wrapper) => `${wrapper}${container}`)
						.join(', ')
				)
				.join(', ');
			smallerContainers = document.querySelectorAll(
				smallerContainerSelector
			);
		}

		if (smallerContainers.length) {
			maxColumnsInContainer = 3;
		}
	}

	return maxColumnsInContainer;
};

/**
 * Method specifically calculates the number of columns to show on the SRP based on the user's screen width and max-width of listings container.
 * If we don't have a value for the container max-width (containerColumnsPossible), we use a default max value so that columns is determined by
 * viewportWidth
 * If you update number of columns per containerColumnsPossible, you must also update in cms-web
 * https://ghe.coxautoinc.com/DDC-WebPlatform/cms-web/blob/master/htdocs/v9/media/sass/ddc-design-system/components/_vehicle-card.scss
 * @param {number} viewportWidth Current width of the screen in px
 * @param {number} containerColumnsPossible The number of columns possible based on container size
 * @returns {number} Number of columns to show on SRP
 */
export const getNumberOfColumns = (
	viewportWidth,
	containerColumnsPossible = DEFAULT_MAX_COLUMNS_ON_SRP
) => {
	let columns;
	if (viewportWidth >= 2000 && containerColumnsPossible >= 4) columns = 4;
	else if (viewportWidth >= 1440 && containerColumnsPossible >= 3)
		columns = 3;
	else if (viewportWidth >= 768 && containerColumnsPossible >= 2) columns = 2;
	else columns = 1;

	return columns;
};
