import { SET_OFFSITE_DATA } from '../actions/types';

export default function offSiteData(state = {}, action) {
	if (!action.error && action.payload) {
		if (action.type === SET_OFFSITE_DATA) {
			return {
				...state,
				...action.payload
			};
		}
	}
	return state;
}
