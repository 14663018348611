import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MyCarsCtas } from 'wsm-mycars-ctas';
import { useLabels } from 'wsm-common-data';
import { selectIsTabbedLayout } from '../../../features/layoutSlice';
import { useMyCarsSiteProperties } from '../../../hooks';

const MyCars = ({
	vehicle: {
		uuid,
		title,
		images,
		pricing,
		accountId,
		bodyStyle,
		make,
		model,
		stockNumber,
		trim,
		type,
		vin,
		year
	}
}) => {
	const labels = useLabels();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const siteId = useSelector((state) => state.widgetInfo.siteId);
	const finalPrice = useMemo(
		() =>
			pricing?.dPrice?.find((price) => price?.isFinalPrice)?.value ||
			null,
		[pricing]
	);
	const { enableMyCars } = useMyCarsSiteProperties();

	const modernMyCarsLabels = [
		{
			id: 'trackPrice',
			default: labels.get('TRACK_PRICE')
		},
		{
			id: 'save',
			default: labels.get('SAVE')
		}
	];

	if (enableMyCars) {
		return (
			<MyCarsCtas
				uuid={uuid}
				title={title}
				price={finalPrice}
				image={images?.[0] || null}
				isInLine={!isTabbedLayout}
				labels={modernMyCarsLabels}
				accountId={accountId}
				siteId={siteId}
				bodyStyle={bodyStyle}
				make={make}
				model={model}
				stockNumber={stockNumber}
				trim={trim}
				type={String(type)}
				vin={vin}
				year={year}
			/>
		);
	} else {
		return null;
	}
};

MyCars.propTypes = {
	vehicle: PropTypes.shape({
		images: PropTypes.arrayOf(
			PropTypes.shape({
				uri: PropTypes.string
			})
		),
		uuid: PropTypes.string,
		link: PropTypes.string,
		pricing: PropTypes.shape({
			dPrice: PropTypes.arrayOf({})
		}),
		title: PropTypes.string,
		accountId: PropTypes.string,
		bodyStyle: PropTypes.string,
		make: PropTypes.string,
		model: PropTypes.string,
		stockNumber: PropTypes.string,
		trim: PropTypes.string,
		type: PropTypes.string,
		vin: PropTypes.string,
		year: PropTypes.number
	}).isRequired
};

export default MyCars;
